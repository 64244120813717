import { CertificateData } from "../models/certificateData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class CertificateService extends BaseCrudService {
  async getCertificatesByEvent(eventId: string) {
    const response = await this.getAll<CertificateData[]>(
      apiRoutes.events + "/" + eventId + "/participation-certificates"
    );
    return response.data;
  }

  async getCertificatesByEventAsZip(eventId: string) {
    const response = await this.get(apiRoutes.certificate, eventId);
    return response.data;
  }
}
