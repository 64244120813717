/* eslint-disable */
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { participantsService } from "../../api/index";
import { getEditRouteBasedOnTrainingType } from "../../routing/routes";
import { ParticipantListData } from "../../models/participantData";
import { clearEventList } from "../events/actions";
import { setOpenTab, getParticipantsTabBasedOnTrainingType } from "../tabs/actions";
import { EventActionTypes } from "./../events/types";
import {
  AddParticipantAction,
  ADD_PARTICIPANT,
  ClearParticipantAction,
  CLEAR_PARTICIPANT,
  EditParticipantStatusAction,
  EDIT_PARTICIPANT_REQUEST,
  EDIT_PARTICIPANT_STATUS,
  ParticipantActionTypes,
  ParticipantEditRequestAction,
  ParticipantFailureAction,
  ParticipantRequestAction,
  PARTICIPANT_FAILURE,
  PARTICIPANT_REQUEST,
  SetParticipantListAction,
  SET_PARTICIPANT_LIST,
} from "./types";
import { history } from "../../routing/history";
import { ELearningDto } from "../../models/elearning";
import { TabsActionTypes } from "../tabs/types";
import { enqueueSnackbar } from "notistack";

export function getParticipants(eventId: string) {
  return (dispatch: Dispatch<ParticipantActionTypes>) => {
    dispatch(participantsRequest());

    participantsService
      .getParticipant(eventId)
      .then((response) => {
        dispatch(SetParticipantToList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        enqueueSnackbar("Die Teilnahmeliste konnte nicht geladen werden!", {
          variant: "error",
        });
      });
  };
}

export function editParticipantStatus(
  event: ELearningDto,
  eventId: string,
  participantId: string,
  participantStatus: number
) {
  return (
    dispatch: Dispatch<ParticipantActionTypes | EventActionTypes | TabsActionTypes>
  ) => {
    dispatch(participantsEditRequest(participantId));
    // const eventId = event.id;

    participantsService
      .editParticipantStatus(eventId, participantId, participantStatus)
      .then(() => {
        dispatch(SetParticipantStatus(participantId, participantStatus));

        dispatch(clearEventList());

        // show participants tab
        // const trainingType = event.training_type;
        // history.push(`${getEditRouteBasedOnTrainingType(trainingType)}/${eventId}`);
        // dispatch(setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType)));
      })
      .catch((err: AxiosError) => {
        dispatch(participantsEditRequest(participantId));
        dispatch(participantFailure(err));
        enqueueSnackbar(
          `Der Status des Teilnehmers konnte nicht geändert werden.\n${ApiHelper.showErrorMessageFromApi(
            err.response?.data
          )}`,
          { variant: "error" }
        );
      });
  };
}

export function editMultipleParticipantStatus(
  event: ELearningDto,
  participants: { participantId: string; participantStatus: number }[]
) {
  return (
    dispatch: Dispatch<ParticipantActionTypes | EventActionTypes | TabsActionTypes>
  ) => {
    participants.forEach(({ participantId, participantStatus }) => {
      dispatch(participantsEditRequest(participantId));
    });
    const eventId = event.id.toString();
    const promises = participants.map(({ participantId, participantStatus }) => {
      return participantsService.editParticipantStatus(
        eventId,
        participantId,
        participantStatus
      );
    });
    Promise.all(promises)
      .then(() => {
        participants.forEach(({ participantId, participantStatus }) => {
          dispatch(SetParticipantStatus(participantId, participantStatus));
        });
        dispatch(clearEventList());
        // show participants tab
        const trainingType = event.training_type;
        history.push(`${getEditRouteBasedOnTrainingType(trainingType)}/${eventId}`);
        dispatch(setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType)));
      })
      .catch((err: AxiosError) => {
        participants.forEach(({ participantId }) => {
          dispatch(participantsEditRequest(participantId));
        });
        dispatch(participantFailure(err));
        enqueueSnackbar(
          `Der Status der Teilnehmer konnte nicht geändert werden.\n${ApiHelper.showErrorMessageFromApi(
            err.response?.data
          )}`,
          { variant: "error" }
        );
      });
  };
}

export function reactivateELearning(eventId: string, participantId: string) {
  return (dispatch: Dispatch<ParticipantActionTypes>) => {
    participantsService
      .editReactivated(eventId, participantId, true)
      .then(() => {
        dispatch(clearParticipant());
        enqueueSnackbar("Die E-Learning-Teilnahme wurde erfolgreich reaktiviert.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        enqueueSnackbar("Die E-Learning-Teilnahme konnte nicht reaktiviert werden!", {
          variant: "error",
        });
      });
  };
}

export function cancelReactivated(eventId: string, participantId: string) {
  return (dispatch: Dispatch<ParticipantActionTypes>) => {
    participantsService
      .editReactivated(eventId, participantId, false)
      .then(() => {
        dispatch(clearParticipant());
        enqueueSnackbar("Die E-Learning-Teilnahme wurde erfolgreich inaktiviert.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        enqueueSnackbar("Die E-Learning-Teilnahme konnte nicht inaktiviert werden!", {
          variant: "error",
        });
      });
  };
}

export function sendEmailToParticipants(eventId: string, message: string) {
  return (dispatch: Dispatch<ParticipantActionTypes>) => {
    participantsService
      .sendEmailToParticipants(eventId, message)
      .then(() => {
        enqueueSnackbar("Nachricht an die Teilnehmer wurde erfolgreich versendet", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        enqueueSnackbar(
          `Nachricht an die Teilnehmer wurde nicht versendet!\n${ApiHelper.showErrorMessageFromApi(
            err.response?.data
          )}`,
          { variant: "error" }
        );
      });
  };
}

export function participantsRequest(): ParticipantRequestAction {
  return {
    type: PARTICIPANT_REQUEST,
  };
}

export function participantsEditRequest(
  participantId: string
): ParticipantEditRequestAction {
  return {
    type: EDIT_PARTICIPANT_REQUEST,
    participantId: participantId,
  };
}

export function participantFailure(error: any): ParticipantFailureAction {
  return {
    type: PARTICIPANT_FAILURE,
    error,
  };
}

export function SetParticipantToList(
  data: ParticipantListData[]
): SetParticipantListAction {
  return {
    type: SET_PARTICIPANT_LIST,
    data,
  };
}

export function SetParticipantStatus(
  participantId: string,
  participantStatus: number
): EditParticipantStatusAction {
  return {
    type: EDIT_PARTICIPANT_STATUS,
    participantId: participantId,
    participantStatus: participantStatus,
  };
}

export function AddParticipantToList(
  participant: ParticipantListData
): AddParticipantAction {
  return {
    type: ADD_PARTICIPANT,
    data: participant,
  };
}

export function clearParticipant(): ClearParticipantAction {
  return {
    type: CLEAR_PARTICIPANT,
  };
}
