/* eslint-disable */
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import {
  ADD_NEW_EVENT_PARTICIPANT,
  ADD_NEW_EVENT_PARTICIPANT_FAILURE,
  ADD_NEW_EVENT_PARTICIPANT_SUCCESS,
  ADD_NEW_EVENT_PARTICIPANT_REQUEST,
  NewEventUserActionTypes,
  NewEventUserState,
  AddNewEventParticipantFailureAction,
  AddNewEventParticipantSuccessAction,
} from "./types";
import { usersService } from "../../api";
import { NewEventParticipantData } from "../../models/userData";
import { enqueueSnackbar } from "notistack";

export const initialNewEventUserState: NewEventUserState = {
  isLoading: false,
  newEventUser: undefined,
};

export function registerEventParticipant(data: NewEventParticipantData) {
  return (dispatch: Dispatch<NewEventUserActionTypes>) => {
    dispatch(addNewEventParticipantRequest());
    try {
      usersService.registerEventParticipant(data).then(() => {
        dispatch(addNewEventParticipantSuccess());
        enqueueSnackbar(
          "Benutzer wurde erfolgreich angelegt und zum Kurs hinzugefügt.",
          {
            variant: "success",
          }
        );
      });
    } catch (error) {
      dispatch(addNewEventParticipantFailure());
      enqueueSnackbar("Nutzer konnte nicht angelegt werden!", {
        variant: "error",
      });
    }
  };
}

export function addNewEventParticipantSuccess(): AddNewEventParticipantSuccessAction {
  return {
    type: ADD_NEW_EVENT_PARTICIPANT_SUCCESS,
  };
}

export function addNewEventParticipantFailure(): AddNewEventParticipantFailureAction {
  return {
    type: ADD_NEW_EVENT_PARTICIPANT_FAILURE,
  };
}

export const addNewEventParticipantRequest = (): NewEventUserActionTypes => ({
  type: ADD_NEW_EVENT_PARTICIPANT_REQUEST,
});

export function NewEventUserReducer(
  state = initialNewEventUserState,
  action: NewEventUserActionTypes
): NewEventUserState {
  switch (action.type) {
    case ADD_NEW_EVENT_PARTICIPANT:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default NewEventUserReducer;
