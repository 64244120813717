/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  ADD_DEFAULT_CERTIFICATES_LIST,
  ADD_CERTIFICATES_LIST,
  CHANGE_DOCUMENT_PUBLISH_STATE,
  CLEAR_CERTIFICATES,
  CERTIFICATES_FAILURE,
  CERTIFICATES_REQUEST,
  CertificatesActionTypes,
  CertificatesState,
  REMOVE_CERTIFICATES_LIST,
  SET_CERTIFICATES_LOADED,
  SET_CERTIFICATES_LIST,
  MARK_DOCUMENT_FOR_DELETE,
} from "./types";
import { CertificateData } from "../../models/certificateData";

export const initialCertificatesState: CertificatesState = {
  certificateList: [],
  certificateListTemp: [],
  certificateListLoaded: false,
  isLoading: false,
  certificatesMarkedForDelete: [],
};

export function certificatesReducer(
  state = initialCertificatesState,
  action: CertificatesActionTypes
): CertificatesState {
  switch (action.type) {
    case CERTIFICATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CERTIFICATES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_CERTIFICATES_LIST:
      return {
        ...state,
        certificateList: action.data,
        isLoading: false,
      };
    case SET_CERTIFICATES_LOADED:
      return {
        ...state,
        certificateListLoaded: true,
        isLoading: false,
      };
    case CLEAR_CERTIFICATES:
      return initialCertificatesState;
    case MARK_DOCUMENT_FOR_DELETE:
      return {
        ...state,
        certificatesMarkedForDelete: state.certificatesMarkedForDelete.concat([
          action.certificate,
        ]),
        isLoading: false,
      };
    default:
      return state;
  }
}

export default certificatesReducer;
