/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ParticipantListData } from "../../../../../models/participantData";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { editParticipantStatus } from "../../../../../redux/participants/actions";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import "./Participants.scss";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../../models/enums/participationStatus.enum";
import { addEventToCart } from "../../../../../redux/cart/actions";
import { useHistory } from "react-router-dom";
import { isEventOver } from "../../shared/checkEventIsOver";
import routes from "../../../../../routing/routes";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import usePermission from "../../../../../services/usePermissions";

interface ParticipantsStatusProps {
  participant: ParticipantListData;
  disabledSelect?: boolean;
  index: number;
  trainingType: TrainingType;
  isPartOfBlendedLearning?: boolean;
}

export const ParticipantsStatus: React.FC<ParticipantsStatusProps> = (props) => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  // TODO: state.event is not updated and NIL_UUID is passed!!!
  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  const eventId = id;

  const { getParticipationStatusForSelectBasedOnUserType } = usePermission(
    currentUser?.user_type
  );

  const options =
    props.trainingType === TrainingType.ELearning
      ? [
          {
            key: "Bestanden",
            value: ELearningParticipationStatus.Passed,
            label: "Bestanden",
          },
          {
            key: "Nicht bestanden",
            value: ELearningParticipationStatus.NotPassed,
            label: "Nicht bestanden",
          },
        ]
      : getParticipationStatusForSelectBasedOnUserType();

  let statusLabel = "Status";
  if (props.trainingType === TrainingType.DefaultEvent && props.isPartOfBlendedLearning)
    statusLabel += " Veranstaltung";
  if (props.trainingType === TrainingType.ELearning) statusLabel += " E-Learning";

  return (
    <CustomSelect
      disabled={props.disabledSelect || props.trainingType === TrainingType.ELearning}
      name={"eventParticipation." + props.index}
      id={"participant-status" + props.index}
      label={statusLabel}
      labelId="participant-status-label"
      value={
        props.trainingType === TrainingType.ELearning
          ? props.participant.elearning_status
          : props.participant.status
      }
      noEmptyDefaultValue
      onChange={(
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        child?: React.ReactNode
      ) => {
        let appendedText = "";
        if (
          event.target.value === ParticipationStatus.Booked &&
          !editEvent.max_seats_taken &&
          props.participant.status !== ParticipationStatus.Booked &&
          !isEventOver(editEvent.end)
        ) {
          appendedText =
            " Sie werden im nächsten Schritt zum Checkout weitergeleitet, welchen Sie stellvertretend für den Nutzer durchführen müssen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          !editEvent.max_seats_taken &&
          props.participant.status !== ParticipationStatus.Booked
        ) {
          appendedText =
            " Die Veranstaltung ist bereits beendet. Sie werden im nächsten Schritt zum Checkout weitergeleitet, welchen Sie stellvertretend für den Nutzer durchführen müssen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          editEvent.max_seats_taken &&
          !isEventOver(editEvent.end)
        ) {
          appendedText =
            " Die Veranstaltung ist ausgebucht. Sie werden im nächsten Schritt zum Checkout weitergeleitet und haben dort die Möglichkeit, die Warteliste für den Nutzer zu übergehen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          editEvent.max_seats_taken
        ) {
          appendedText =
            " Die Veranstaltung ist bereits beendet und ausgebucht. Sie werden im nächsten Schritt zum Checkout weitergeleitet und haben dort die Möglichkeit, die Warteliste für den Nutzer zu übergehen.";
        }

        dispatch(
          showDialog({
            title: "Status des Teilnehmers ändern",
            message:
              "Sind Sie sich sicher, dass Sie den Status von " +
              props.participant.user.fullname +
              ' auf "' +
              options.filter((o) => o.value === event.target.value)[0]?.label +
              '" ändern möchten?' +
              appendedText,
            action: () => {
              if (
                event.target.value === ParticipationStatus.Booked &&
                props.participant.status !== ParticipationStatus.Booked
              ) {
                // when moving participant to booked manually
                // TODO: +id and id of type number should stay for cartData
                dispatch(addEventToCart(id, props.participant.user.id));
                history.push(routes.checkout);
              } else {
                dispatch(
                  editParticipantStatus(
                    editEvent,
                    eventId,
                    props.participant.id,
                    event.target.value as number
                  )
                );
              }
            },
          })
        );
      }}
      options={options}
    />
  );
};

// TODO no translation yet
