export const acceptedFileTypes = {
  defaultTraining: {
    title: "PDF-, Excel-, Word-, und MP4- (max. 2GB) Dateien",
    dropZoneText: "Hier klicken oder Dateien (PDF, Word, Excel, MP4) ablegen",
    types: [
      "application/pdf",
      "video/mp4",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },

  trainingWithScorm: {
    title: "PDF-, Excel-, Word- und SCORM-Dateien",
    dropZoneText:
      "Hier klicken oder Dateien (PDF, Word, Excel, SCORM (max. 2GB)) ablegen",
    types: [
      "application/zip",
      "application/x-zip",
      "application/x-zip-compressed",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
};
