/* eslint-disable */
import { Box, Button, Grid, Link } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import Loader from "../../../theming/loader/Loader";
import apiRoutes from "../../../../api/apiRoutes";
import { useState } from "react";
import {
  getCertificatesByEvent,
  setCertificatesLoaded,
} from "../../../../redux/certificates/actions";
import "./Certificates.scss";

interface CertificateProps {
  eventId: string;
}

export const Certificates: React.FC<CertificateProps> = (props) => {
  const { eventId } = props;

  const dispatch = useDispatch();

  const certificates = useSelector((state: AppState) => state.certificates);
  const [certificateLink, setCertificateLink] = useState<string>();
  const [zipCertificateLink, setZipCertificateLink] = useState<string>();

  useEffect(() => {
    setCertificateLink(
      "/api" +
        apiRoutes.events +
        "/" +
        eventId +
        "/participation-certificates-merged-pdf"
    );
    setZipCertificateLink(
      "/api" +
        apiRoutes.events +
        "/" +
        eventId +
        "/zip-download-participation-certificates"
    );
  }, [eventId]);

  useEffect(() => {
    if (!certificates.isLoading && !certificates.certificateListLoaded) {
      dispatch(getCertificatesByEvent(eventId));
      dispatch(setCertificatesLoaded());
    }
  }, [certificates, eventId]);

  if (certificates.isLoading && !certificates.certificateListLoaded) {
    return <Loader />;
  }

  if (certificates.certificateList.length === 0) {
    return (
      <>
        <h3>Teilnehmerzertifikate</h3>
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "6.25rem" }}
        >
          Veranstaltung enthält keine Zertifikate
        </Box>
      </>
    );
  }

  return (
    <>
      <h3>Teilnehmerzertifikate</h3>
      <Grid item>
        <Grid container className="donwload-area" spacing={1}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>Alle Teilnehmerzertifikate herunterladen:</p>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Button
              id="download-certificates-zip"
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
              onClick={() => {
                console.log("clicked");
                if (certificateLink) {
                  window.open(zipCertificateLink, "_blank");
                }
              }}
            >
              als zip-Datei
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Button
              id="download-certificates-single-pdf"
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
              onClick={() => {
                console.log("clicked");
                if (certificateLink) {
                  window.open(certificateLink, "_blank");
                }
              }}
            >
              als zusammengefügte PDF-Datei
            </Button>
          </Grid>
        </Grid>
        <Grid container className={"certificates-list-itemsHeader"}>
          <Grid item xs={1} sm={1} md={1} lg={1} style={{ textAlign: "left" }}>
            <b>Download</b>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <b>Teilnehmer</b>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <b>Zertifikatsnummer</b>
          </Grid>
        </Grid>

        {certificates.certificateList.map((certificate, index) => {
          return (
            <Grid container key={index} className="certificates-list-item">
              <Grid item xs={1} sm={1} md={1} lg={1} style={{ textAlign: "center" }}>
                <Link
                  href={
                    "/api" + apiRoutes.certificate + "/" + certificate.id + "/download"
                  }
                  target={"_blank"}
                >
                  <GetAppIcon />
                </Link>
              </Grid>
              <Grid xs={9} sm={9} md={9} lg={9}>
                {certificate?.user}
              </Grid>
              <Grid xs={2} sm={2} md={2} lg={2}>
                {certificate?.certificate_id}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

// TODO no translation yet
