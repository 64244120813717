import { CertificateData } from "../../models/certificateData";

export interface CertificatesState {
  certificateList: CertificateData[];
  certificateListTemp: CertificateData[]; // is needed to determine which certificates are added newly
  certificateListLoaded: boolean;
  isLoading: boolean;
  certificatesMarkedForDelete: Array<CertificateData>;
}

export const CERTIFICATES_REQUEST = "CERTIFICATES_REQUEST";
export const CERTIFICATES_FAILURE = "CERTIFICATES_FAILURE";

export const ADD_CERTIFICATES_LIST = "ADD_CERTIFICATES_LIST";
export const SET_CERTIFICATES_LIST = "SET_CERTIFICATES_LIST";
export const ADD_DEFAULT_CERTIFICATES_LIST = "ADD_DEFAULT_CERTIFICATES_LIST";
export const REMOVE_CERTIFICATES_LIST = "REMOVE_CERTIFICATES_LIST";
export const CHANGE_DOCUMENT_PUBLISH_STATE = "CHANGE_DOCUMENT_PUBLISH_STATE";

export const SET_CERTIFICATES_LOADED = "SET_CERTIFICATES_LOADED";
export const CLEAR_CERTIFICATES = "CLEAR_CERTIFICATES";

export const MARK_DOCUMENT_FOR_DELETE = "MARK_DOCUMENT_FOR_DELETE";

export interface CertificatesRequestAction {
  type: typeof CERTIFICATES_REQUEST;
}

export interface CertificatesFailureAction {
  type: typeof CERTIFICATES_FAILURE;
}

export interface AddCertificatesListAction {
  type: typeof ADD_CERTIFICATES_LIST;
  data: CertificateData;
}

export interface SetCertificatesListAction {
  type: typeof SET_CERTIFICATES_LIST;
  data: CertificateData[];
}

export interface AddDefaultCertificatesListAction {
  type: typeof ADD_DEFAULT_CERTIFICATES_LIST;
  data: CertificateData[];
  defaultType: string;
}

export interface RemoveCertificatesListAction {
  type: typeof REMOVE_CERTIFICATES_LIST;
  data: CertificateData;
}

export interface ChangeCertificatesPublishStateListAction {
  type: typeof CHANGE_DOCUMENT_PUBLISH_STATE;
  data: CertificateData;
}

export interface SetCertificatesLoadedAction {
  type: typeof SET_CERTIFICATES_LOADED;
}

export interface ClearCertificatesAction {
  type: typeof CLEAR_CERTIFICATES;
}

export interface MarkCertificateForDelete {
  type: typeof MARK_DOCUMENT_FOR_DELETE;
  certificate: CertificateData;
}

export type CertificatesActionTypes =
  | CertificatesRequestAction
  | CertificatesFailureAction
  | AddCertificatesListAction
  | SetCertificatesListAction
  | AddDefaultCertificatesListAction
  | RemoveCertificatesListAction
  | ChangeCertificatesPublishStateListAction
  | SetCertificatesLoadedAction
  | ClearCertificatesAction
  | MarkCertificateForDelete;
