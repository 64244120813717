/* eslint-disable */
import { Button, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import CustomTextField from "../../../../forms/CustomTextField";

interface IChecklistSpeakerProps {}

const ChecklistSpeaker: React.FunctionComponent<IChecklistSpeakerProps> = (props) => {
  const lecture = useSelector((state: AppState) => state.lectures);
  const checklist = useSelector((state: AppState) => state.checklist);
  const [checklistIds, setChecklistIds] = React.useState<string[]>(
    lecture.lectureDetails.checklist
  );
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Grid item xs={12}>
        Hier sehen Sie eine Übersicht aller Teilnehmerzertifikate für die Veranstaltung{" "}
        <b>{lecture.lectureDetails.title}</b>, welche Sie herunterladen und ausdrucken
        können.
      </Grid>
      {checklist.checklist.map((checklistItem, index) => {
        return (
          <Grid item xs={12} sm={6} key={index}>
            <CustomCheckbox
              name={"checklistId-" + checklistItem.id}
              label={checklistItem.name}
              value={checklistItem.id}
              defaultChecked={lecture.lectureDetails.checklist.includes(
                checklistItem.id
              )}
              onChange={(checkbox: ChangeEvent<HTMLInputElement>) => {
                if (checkbox.target.checked) {
                  setChecklistIds([...checklistIds, checkbox.target.value]);
                } else {
                  let index = checklistIds.indexOf(checkbox.target.value);
                  if (index !== -1) {
                    checklistIds.splice(index, 1);
                    setChecklistIds([...checklistIds]);
                  }
                }
              }}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          name="checklist_additional_info"
          id="checklist_additional_info"
          label="Zusätzliche Materialien"
          multiline={true}
          rows={10}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          onClick={() => {
            setFieldValue("checklist", checklistIds);
            setFieldValue("submitChecklist", true);
          }}
        >
          Materialliste speichern
        </Button>
      </Grid>
    </>
  );
};

export default ChecklistSpeaker;

// TODO no translations yet
