import { NewEventParticipantData } from "../../models/userData";

export interface NewEventUserState {
  isLoading: boolean;
  newEventUser?: NewEventParticipantData;
}

export const ADD_NEW_EVENT_PARTICIPANT_REQUEST = "ADD_NEW_EVENT_PARTICIPANT_REQUEST";
export const ADD_NEW_EVENT_PARTICIPANT_SUCCESS = "ADD_NEW_EVENT_PARTICIPANT_SUCCESS";
export const ADD_NEW_EVENT_PARTICIPANT_FAILURE = "ADD_NEW_EVENT_PARTICIPANT_FAILURE";

export const ADD_NEW_EVENT_PARTICIPANT = "ADD_NEW_EVENT_PARTICIPANT";

export interface AddNewEventParticipantRequestAction {
  type: typeof ADD_NEW_EVENT_PARTICIPANT_REQUEST;
}

export interface AddNewEventParticipantFailureAction {
  type: typeof ADD_NEW_EVENT_PARTICIPANT_FAILURE;
}

export interface AddNewEventParticipantSuccessAction {
  type: typeof ADD_NEW_EVENT_PARTICIPANT_SUCCESS;
}

export interface AddNewEventParticipantAction {
  type: typeof ADD_NEW_EVENT_PARTICIPANT;
  newEventUser: NewEventParticipantData;
}

export type NewEventUserActionTypes =
  | AddNewEventParticipantRequestAction
  | AddNewEventParticipantFailureAction
  | AddNewEventParticipantSuccessAction
  | AddNewEventParticipantAction;
