/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";
import CustomPasswordField from "../../../../forms/CustomPasswordField";
import CustomDatepicker from "../../../../forms/CustomDatepicker";
import i18n from "../../../../../localization/i18n";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../../../libs/yup-validations";
import { NewEventParticipantData } from "../../../../../models/userData";
import {
  NewEventParticipantinitialData,
  userNeedsAGuardian,
} from "../../../../../pages/auth/registration/RegistrationPage.utils";
import SalutationSelect from "../../../../forms/selects/SalutationSelect";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { AppState } from "../../../../../redux";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import { registerEventParticipant } from "../../../../../redux/participant/actions";
import { getParticipants } from "../../../../../redux/participants/actions";
import { AxiosError } from "axios";
import { usersService } from "../../../../../api";

interface AddParticipantProps {
  event_id: string;
}

export const AddParticipant: React.FC<AddParticipantProps> = (props) => {
  const { event_id } = props;

  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [guardianRequired, setGuardianRequired] = useState(false);

  const [userMail, setUserMail] = useState<string | undefined>(undefined);

  const [minimumAge, setMinimumAge] = useState<number>(0);
  const minimumAgeFromApi = useSelector((state: AppState) => state.minimumAge);

  const currentDateLocal = new Date();
  const today = currentDateLocal.toDateString();

  const [birthday, setBirthday] = useState<MaterialUiPickersDate>();

  const [newUser, setNewUser] = useState(true);

  const participants = useSelector((state: AppState) => state.participant);

  useEffect(() => {
    if (!minimumAgeFromApi?.isLoading && minimumAgeFromApi?.minimumAge?.minimum_age) {
      setMinimumAge(minimumAgeFromApi.minimumAge.minimum_age);
    }
  }, [minimumAgeFromApi]);

  const {
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationEmail,
    YupValidationConfirmEmail,
    YupValidationBirthday,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationCity,
    YupValidationZipCode,
    YupValidationConfirmPassword,
    YupValidationSalutation,
  } = useDynamicYupValidations();

  const newEventParticipantValidationSchema = yup.object({
    first_name: YupValidationFirstName,
    last_name: YupValidationLastName,
    email: YupValidationEmail,
    email_confirmation: YupValidationConfirmEmail("email"),
    birthday: YupValidationBirthday,
    salutation: YupValidationSalutation,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    city: YupValidationCity,
    zip_code: YupValidationZipCode,
    password: YupValidationConfirmPassword("confirmPassword"),
    confirmPassword: YupValidationConfirmPassword("password"),
  });

  const handleSubmit = async (
    values: any
    // { resetForm }: { resetForm: () => void }
  ) => {
    console.log("submitData");
    console.log("values: ", values);

    try {
      const requestData: NewEventParticipantData = {
        first_name: values.first_name,
        last_name: values.last_name,
        salutation: values.salutation,
        guardian_email: values.guardian_email,
        email: values.email,
        birthday: moment(values.birthday).format("DD.MM.YYYY"),
        street: values.street,
        street_number: values.street_number,
        zip_code: values.zip_code,
        city: values.city,
        password: values.password,
        event_id: event_id,
      };

      //const success = await dispatch(registerEventParticipant(requestData));
      //console.log("success: ", success);
      // @ts-ignore

      usersService
        .registerEventParticipant(requestData)
        .then((result: any) => {
          if (result) {
            enqueueSnackbar("Benutzer wurde erfolgreich zum Kurs hinzugefügt.", {
              variant: "success",
            });
            values = NewEventParticipantinitialData;
            dispatch(getParticipants(event_id));
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });

      //dispatch(getParticipants(event_id));
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  useEffect(() => {
    dispatch(getParticipants(event_id));
  }, [event_id]);

  return (
    <>
      <h2>Teilnehmer anlegen und hinzufügen</h2>
      <Formik
        key={i18n.language}
        initialValues={NewEventParticipantinitialData}
        validationSchema={newEventParticipantValidationSchema}
        validateOnChange
        isInitialValid
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, setFieldValue, resetForm }) => (
          <Form className="newEventParticipantForm">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/** TODO: Is this still needed?
                <CustomCheckbox
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setNewUser(!newUser);
                  }}
                  name="new-user"
                  label="Neuen Benutzer anlegen"
                  checked={newUser}
                />
                */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={SalutationSelect}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="salutation"
                      id="salutation-registration"
                      variant="filled"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="first_name"
                      id="first-name-registration"
                      variant="filled"
                      label={t("profile.firstName", { ns: "common" })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="last_name"
                      id="last-name-registration"
                      variant="filled"
                      label={t("profile.lastName", { ns: "common" })}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="RegistrationPage"
                  fullWidth
                  type="E-Mail"
                  name="email"
                  id="email-registration"
                  variant="filled"
                  label={t("profile.mail", { ns: "common" })}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const lowercaseEmail = e.target.value.toLowerCase();
                    handleChange(e.target.name)(lowercaseEmail);
                    setUserMail(lowercaseEmail);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="RegistrationPage"
                  fullWidth
                  type="E-Mail"
                  name="email_confirmation"
                  id="email-confirmation"
                  variant="filled"
                  label={t("profile.mailConfirmation", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Field
                  as={CustomDatepicker}
                  fullWidth
                  autoComplete="birthday"
                  name="birthday"
                  id="birthday-registration"
                  label={t("profile.birthdate", { ns: "common" })}
                  value={values.birthday ?? ""}
                  customChangeHandler={(date: MaterialUiPickersDate) => {
                    setFieldValue("birthday", date);
                    setBirthday(date);
                    const dateString = date ? date.toISOString() : "";
                    setGuardianRequired(userNeedsAGuardian(dateString, minimumAge));
                  }}
                  required
                />
              </Grid>
            </Grid>
            <div className="RegistrationAddress" style={{ width: "100%" }}>
              <h3>{t("profile.addressHeader", { ns: "common" }).toUpperCase()}</h3>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-registration"
                  name="street"
                  label={t("profile.street", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-number-registration"
                  name="street_number"
                  label={t("profile.streetNumber", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="zip-code-registration"
                  name="zip_code"
                  label={t("profile.zipCode", { ns: "common" })}
                  required
                />
              </Grid>{" "}
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="city-registration"
                  name="city"
                  label={t("profile.city", { ns: "common" })}
                  required
                />
              </Grid>
            </Grid>

            <div className="RegistrationAdress" style={{ width: "100%" }}>
              <h3>{t("profile.password", { ns: "common" }).toUpperCase()}</h3>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Field
                  required
                  as={CustomPasswordField}
                  fullWidth
                  id="password"
                  label={t("profile.password", { ns: "common" })}
                  name="password"
                />
              </Grid>{" "}
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomPasswordField}
                  fullWidth
                  required
                  id="confirm-password"
                  label={t("password.forgotten.confirm", {
                    ns: "authentication",
                  })}
                  name="confirmPassword"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  id="login"
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disableRipple
                  onClick={() => {
                    {
                      handleSubmit(values);
                      resetForm();
                    }
                  }}
                >
                  Teilnehmer hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
