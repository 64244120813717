/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { certificateService } from "../../api/index";
import { CertificateData } from "../../models/certificateData";
import {
  ClearCertificatesAction,
  CLEAR_CERTIFICATES,
  CertificatesActionTypes,
  CertificatesFailureAction,
  CertificatesRequestAction,
  CERTIFICATES_FAILURE,
  CERTIFICATES_REQUEST,
  SetCertificatesListAction,
  SetCertificatesLoadedAction,
  SET_CERTIFICATES_LIST,
  SET_CERTIFICATES_LOADED,
} from "./types";
import { enqueueSnackbar } from "notistack";

export function getCertificatesByEvent(eventId: string) {
  return (dispatch: Dispatch<CertificatesActionTypes>) => {
    dispatch(certificatesRequest());
    certificateService
      .getCertificatesByEvent(eventId)
      .then((response) => {
        dispatch(setCertificatesList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(certificatesFailure());
        enqueueSnackbar("Beim Laden der Zertifikate ist ein Fehler aufgetreten!", {
          variant: "error",
        });
      });
  };
}

export function certificatesRequest(): CertificatesRequestAction {
  return {
    type: CERTIFICATES_REQUEST,
  };
}

export function certificatesFailure(): CertificatesFailureAction {
  return {
    type: CERTIFICATES_FAILURE,
  };
}

export function setCertificatesList(data: CertificateData[]): SetCertificatesListAction {
  return {
    type: SET_CERTIFICATES_LIST,
    data,
  };
}

export function setCertificatesLoaded(): SetCertificatesLoadedAction {
  return {
    type: SET_CERTIFICATES_LOADED,
  };
}

export function clearCertificates(): ClearCertificatesAction {
  return {
    type: CLEAR_CERTIFICATES,
  };
}
